.DashboardCard {
    width: 240px;
    height: 240px;
    /* border: 2px solid #4318ff !important; */
    border-radius: 25px !important;
    vertical-align: center;
    text-align: center;
    justify-content: center;
}

.header {
    padding: 10px 0;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    background-color: #ffffff;
    font-family: Inter;
    color: #2b3674;
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
}
.bottomContainer {
    /* White */
    padding: 20px;
    border-radius: 20px;
    box-shadow: 10px;
    position: relative;
    background: #ffffff;
    /* line2 */
    height: 100%;
    border: 1px solid #dfe3e9;
}

.casereportContainer {
    background-color: #f4f7fe;
    min-height: 120vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    color: #1b2559;
}
.casereportContainer textarea {
    background: #ffffff;
    /* blue 400 */

    border: 1px solid #868cff;
    border-radius: 4px;
}
.mainContainer {
    background-color: #f4f7fe;
    height: fit-content;
    width: 90vw;
}
.iconStyle {
    color: #1b2559 !important;
    font-size: 64px;
}

.cardTitle {
    color: #2b3674;
}

.TableContainer {
    border-radius: 2% !important;
    overflow: hidden;
}

.TableContainer {
    padding: 1% 1%;
    background-color: #ffffff;
    border-radius: 20px !important;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
    margin: 2%;
    width: 100%;
}

.TableData {
    width: 100%;
    background-color: #fff;
    align-items: flex-start;
}

.blur {
    filter: blur(5px);
}

.confirmModalStyle {
    border-radius: 5px;
    border: 1px solid #4318ff;
}

.solidButton {
    align-items: center;
    color: #f4f7fe !important;
    background: #4318ff !important;
    box-sizing: border-box;
    border-radius: 6px !important;
}
.solidButtonFactory {
    align-items: center;
    color: #f4f7fe !important;
    background: #4318ff !important;
    box-sizing: border-box;
    border-radius: 4px !important;
    padding: 9.7px 12px;
    width: 93px;
}
.outlineButton {
    align-items: center;
    color: #4318ff !important;
    background: #ffffff !important;
    box-sizing: border-box;
    border-radius: 6px !important;
}
.rowContainer {
    text-align: left;
    padding: 1rem 0;
    margin: 1rem;
}

.colLeft {
    padding: 0 1rem !important;
    justify-content: left !important;
    display: flex !important;
    align-items: center !important;
}

.colRight {
    padding: 0 1rem !important;
    justify-content: right !important;
    display: flex !important;
    align-items: center !important;
}

.divider {
    background: #e2e5ed;
    height: 1px;
    width: 100%;
}
.disableColor {
    color: #474747 !important;
}
.scrollableContainer {
    overflow-y: auto;
    height: 350px;
    margin-top: 20px;
}
