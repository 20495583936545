.wholeContainer {
    background: linear-gradient(106.19deg, #f4f7fe 0%, #bfc2ff 100%);
    text-align: left;
    color: #1b2559;
}

.mainContainer {
    background-color: white;
    margin: 3%;
    border-radius: 5px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding: 1%;
}

.header {
    padding: 20px 0 0 0;
    margin-top: 2em;
    margin-left: 2em;
    font-family: Inter;
    color: #2b3674;
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
}

.tabContent {
    padding: 30px 40px;
    position: relative;
}

.footer {
    position: relative;
    display: flex;
    justify-content: space-between;
    background-color: transparent;
}

.footerhelp {
    margin-left: -30px;
    margin-right: 5px;
    font-size: 18px;
}
.caseRepDivider {
    height: 1px;
    background: #a3aed0;
}

.button {
    border-radius: 4px;
    height: 40px;
}
