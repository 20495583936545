.heading {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    padding-bottom: 10px;
    line-height: 100%;
    width: 50em;

    /* identical to box height, or 30px */

    display: flex;
    align-items: flex-end;
    vertical-align: middle;
    font-feature-settings: "tnum" on, "lnum" on;

    color: #000000;
}

.heading h1 {
    font-size: 20px !important;
}

.heading h1 {
    font-size: 20px !important;
}

.CaseUploadContainer {
    margin: 2.5em;
    overflow-x: hidden;
}

.divider {
    background: #e2e5ed;
    height: 1px;
    width: 100%;
}
.dividerBlue {
    background: #a3aed0;
    height: 1px;
}
.tabs {
    padding-top: 1%;
    font-family: Inter;
    font-weight: 300;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 60%;
}

.tabs .active {
    font-weight: 500;
}

.mainContainer {
    height: 50vh;
    display: flex;
    flex-direction: row;

    align-items: center;
}

.mainContainer > * {
    flex: 1;
}

/* .active {
	position: relative;
	margin-bottom: 0px;
	border-bottom: 3px solid #4318ff;
} */
.CaseUploadContainer h4 {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    display: flex;
    align-items: flex-end;
    letter-spacing: 0.022em;
    text-transform: uppercase;
    font-feature-settings: "ss01" on;

    /* #1B2559 */

    color: #1b2559;
}
.dragbox {
    background: #fcfcfc;
    /* #A3AED0 */
    width: 100%;
    height: 148px;
    border: 1px dashed #a3aed0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dragbox span {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* or 21px */
    text-decoration-line: underline;
    font-feature-settings: "tnum" on, "lnum" on;
    /* #4318FF */
    color: #4318ff;
}

input[type="textarea"] {
    height: 10em !important;
}

.button {
    width: 130px;
    height: 52px;
    background-color: #4318ff;
    color: whitesmoke !important;
    border: none;
    margin: 4% 0;
}
.tabs > * {
    cursor: pointer;
}

/* .InPerson {
	display: flex;
	flex-direction: column;
	gap: 20px;
} */
.flexCol {
    display: flex;
    flex-direction: column;
}
.InPerson h3,
.InPerson label {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 143%;
    /* identical to box height, or 20px */

    letter-spacing: 0.018em;

    /* #1B2559 */

    color: #1b2559;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

h4 {
    margin-bottom: 0px;
    margin-top: 10px;
}

.mandatory {
    position: absolute;
    bottom: 10px;
    left: 10px;
}
.crossIcon {
    position: absolute;
    right: 4em;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0;
}
.contactBox {
    width: 20%;
}

.solidButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 24px;
    color: #f4f7fe !important;
    margin: 10px;
    background: #4318ff !important;
    box-sizing: border-box;
    border-radius: 4px;
}

.topRowElements {
    padding-top: 3%;
}

.textElementLeft {
    /* padding-right: 5% !important; */
    border-radius: 20px;
    /* margin: 5px; */
}

.active {
    position: relative;
}
.active::before {
    content: "";
    position: absolute;
    bottom: -20%;
    left: -5px;
    height: 6px;

    background: #4318ff;
    border-radius: 6px;
    width: 110%;
}
.tabs li {
    cursor: pointer;
}

.phoneNo > label {
    font: "DM Sans" !important;
    font-style: bold !important;
}

.submitButton {
    background-color: #4318ff;
    color: #f4f7fe;
    width: 144px;
    height: 42px;
    border-radius: 4px;
}

.lable {
    width: 90%;
    font-family: "DM Sans";
    font-weight: 900;
    font-size: 16px !important;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #1b2559 !important;
}
.anotherCaseButton {
    position: float;
    background-color: #4318ff;
    color: #f4f7fe;
    border-radius: 4px;
    width: 245px;
    height: 42px;
}

.successfulUpload {
    vertical-align: center;
    text-align: left;
    padding-top: 5%;
    padding-bottom: 5%;
}

.successfulUpload > * {
    color: #1b2559;
}

.successfulUpload > h3 {
    font-size: 16px;
    font-weight: 400;
    font-family: "Inter";
    position: relative;
}

.successfulUpload > h1 {
    font-size: 24px;
    font-weight: 600;
    font-family: "DM Sans";
}

.successfulUpload > p {
    font-size: 16px;
    font-weight: 400;
    font-family: "Inter";
    color: #1b2559 !important;
    float: right;
}
.anotherCasebtCont {
    float: right;
    padding-top: 2%;
}
.disabled {
    background-color: #a3aed0;
    background-color: rgb(175, 175, 175);
    cursor: not-allowed;
}
