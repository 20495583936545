.container {
    background: #ffffff;
    border-radius: 16px;
    width: 100%;
    text-align: left;
    padding: 12px;
    font-family: "DM Sans";
    font-weight: 700;
    z-index: 1;
}
.QualityChart {
    width: 50%;
    height: fit-content;
    position: relative;
}
.overlay {
    background: rgba(192, 192, 192, 0.3);
    position: absolute;
    border-radius: 16px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.compliance {
    background: #ffffff;
    padding: 22px 12px;
}
.eligibleContainer {
    width: 60%;
}
.eligibleRightContainer {
    width: 50%;
    background: #ffffff;
    border-radius: 0 16px 16px 0;
    display: flex;
    flex-direction: column;
    padding: 2rem 0 0 2rem;
    text-align: start;
}
.eligibleRightContainer > div {
    align-items: right;
    display: flex;
    margin: 0.5rem;
}
.eligibleRightContainer > div > img {
    margin: 0 1rem;
}

.flexAlign {
    display: flex;
    align-items: center;
}
.functionBox {
    margin-left: 2rem;
    position: relative;
    cursor: pointer;
}
.formulaBox {
    left: 0;
    width: 20rem;
    padding: 1rem;
    top: 2rem;
    z-index: 6;
    word-wrap: wrap;
    position: absolute;
    background-color: white;
    border-radius: 12px;
    border: 1px solid #868cff;
    color: black;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}
