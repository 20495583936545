.mainContainer {
    border-top: 1px solid #e2e5ed;
    min-height: 60vh;
    background-color: #ffffff;
    border-radius: 20px;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 1%;
    margin-bottom: 1%;
}

.error-row {
    background-color: red;
}

.label {
    color: #1b2559;
    font-size: 16px;
}
.eventDetails {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 6px 4px 5px;
    gap: 10px;
    position: absolute;
    background: rgba(134, 140, 255, 0.1);
    border: 1px solid #4318ff;
    border-radius: 8px;
}

.footer {
    display: flex;
    justify-content: flex-end;
    margin: 5px 10px;
}
