.heading {
    vertical-align: middle;
    font-size: 1.85rem;
    font-weight: 600;
    color: #2b3674;
    display: flex;
    font-family: Inter;
    line-height: 24px;
}

.caseReportRow {
    padding: 2rem;
    display: grid;
    grid-template-columns: 20% 20%;
    justify-content: space-between;
}

.caseReportBody {
    font-family: Inter;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
    margin: 0 2rem 1rem 2rem;
    padding: 0.5rem;
    height: 70vh;
}

.headingContainer {
    display: flex;
    justify-items: center;
    align-items: center;
}
