.subHeading {
    line-height: 20px;
    text-align: left;
    padding: 0 2.5rem 3rem;
}
.backBtn {
    text-align: left;
    padding: 0 2.5rem 2rem;
}
#emailError {
    position: relative;
    border: 1px solid red;
}
#emailError::after {
    background-color: white;
    position: absolute;
    bottom: -50%;
    color: red;
    content: "Please Enter the registered Email";
}
