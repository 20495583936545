.Home {
    display: grid;
    height: 100%;
    /* grid-template-columns: 100; */
    background-color: #f4f7ff;
}

@media only screen and (max-width: 600px) {
    .Home {
    }
}
