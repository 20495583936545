.modalHeader {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    text-align: start;
}

.modalContent {
    font-size: 14px;
    text-align: start;
}

.confimButton {
    margin: 1rem;
    background-color: #d6e0ff;
    width: 232px;
    border-radius: 4px;
    border: 1px solid;
    padding: 10px 9px;
    font-weight: 500;
    cursor: pointer;
}

.confimButton:hover {
    background-color: #c1cbe8;
}
.modalActions {
    text-align: end;
}
