.ButtonCard {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    width: 268px;
    font-size: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 32px;
    padding: 15px;
    border-radius: 20px;
    margin: 10px;
    /* or 178% */
    background-color: white;
    /* Secondary/Dark Grey/900 */

    color: #1b2559;
    transition: all 0.3s ease-in-out;
}

.ButtonCard:hover {
    background-color: rgba(27, 37, 89, 0.1);
}

.ButtonLogo {
}

.ButtonIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.logoContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ButtonCard h3 {
    margin: 0;
    margin-left: 20px;
    color: #1b2559;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}
