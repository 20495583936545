/* TimerButton.module.css */
.timerButton {
    padding: 9px 24px;
    border-radius: 4px;
    font-weight: bold;
    color: #fff; /* Starting color - white, will change when enabled */
    background-color: #fff; /* Starting background - white, will change when enabled */
    background-image: linear-gradient(to right, #ab9aef 0%, #6641fd 100%);
    background-repeat: no-repeat;
    background-size: 0 100%;
    background-position: left bottom;
    transition: background-size 5s linear, color 0.3s, background-color 0.3s,
        border-color 0.3s;
    cursor: pointer;
    outline: none;
    border: 1px solid #aaa; /* Starting border - light gray, will change when enabled */
    margin: 10px 5px;
    overflow: hidden; /* Ensure the background doesn't bleed outside the border-radius */

    &:disabled {
        color: #aaa;
        background-color: #fff;
        border-color: #aaa;
        cursor: not-allowed;
    }

    &:not(:disabled) {
        color: #f4f7fe;
        background-color: #4318ff;
        border-color: #4318ff;
    }

    &:hover:not(:disabled) {
        background-color: #6641fd;
        border-color: #6641fd;
    }
}
.button {
    border-radius: 4px;
    height: 40px;
}
