.labelPair {
    display: grid;
    grid-template-columns: 40% 60%;
    margin: 1rem 2rem 2rem 2rem;
    font-size: 1rem;
    justify-content: space-between;
    align-items: center;
}

.label {
    justify-self: flex-start;
    font-weight: bold;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    color: #1b2559;
}

.displayLabel {
    border: none;
    border-bottom: 1px #bebebe solid;
    width: 100%;
    text-align: left;
    padding-left: 1rem;
    resize: none;
    height: max-content;
    justify-self: end;
    align-self: end;
}

.caseReportTabContainer {
    padding: 0.5rem 1rem 2rem 1rem;
    height: 100%;
}

.displayLabel #textArea {
    max-height: 7rem;
    overflow: scroll;
}

.container {
    display: block;
    padding: 1rem 0 0 0;
    overflow-y: scroll;
}

.leftColumn,
.rightColumn {
    display: inline-block;
    vertical-align: top;
    width: 48%;
}
