.casereportContainer {
    background-color: #f4f7fe;
    min-height: 120vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    color: #1b2559;
    position: relative;
}
.casereportContainer textarea {
    background: #ffffff;
    border: 1px solid #868cff;
    border-radius: 4px;
}
.mainContainer {
    background-color: #f4f7fe;
    height: fit-content;
    width: 90vw;
}
.header {
    padding: 20px 0;
    margin-top: 2em;
    background-color: #f4f7fe;
    font-family: Inter;
    color: #2b3674;
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
}
.headerRight {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    justify-content: space-between;
}
.header h4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 18%;
    justify-content: space-between;
}
.header h4 img {
    cursor: pointer;
    width: 34px;
    height: 34px;
}
.casereportContainer h4 {
    margin: 0;
}
.bottomContainer {
    /* White */
    padding: 20px;

    position: relative;
    background: #ffffff;
    /* line2 */
    height: 100%;
    border: 1px solid #dfe3e9;
}
.cross {
    position: absolute;
    top: -50px;
    right: 10px;
    font-size: 2em;
    font-weight: 900;
    cursor: pointer;
}
.mainContainer ul {
    display: flex;
    flex-direction: row;
    width: 35%;
    align-items: center;
    justify-content: space-around;
}
.mainContainer ul > li {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    margin: 0 10px;
    /* identical font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 150%;to box height, or 24px */

    display: flex;
    align-items: flex-end;
    letter-spacing: 0.022em;
    /* text-transform: uppercase; */
    font-feature-settings: "ss01" on;

    /* #1B2559 */

    color: #1b2559;
}

.tabContent {
    padding: 30px 40px;
    position: relative;
}
.mandatoryBottom {
    position: absolute;
    left: 50px;
}

.tabItem {
    margin: 20px 10px !important;
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */

    /* #1B2559 */

    color: #1b2559;
}

.tabItem h4 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    justify-self: flex-start;
    /* identical to box height, or 150% */

    letter-spacing: -0.02em;

    /* Text */

    color: #1b2559;
}
.tabItem p {
    margin: 0;
    justify-self: flex-start;
}
.audioItem {
    width: 100%;
    margin: 0;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.caseDetailsSection {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.caseDetailsRight {
    width: 50%;
}
.SMSContent textarea {
    background: #ebedf4;
}

.caseReportHeading {
    margin-top: 20px;
    font-family: Inter;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 21px;
    /* identical to box height, or 150% */

    /* #1B2559 */

    color: #1b2559;
}
.casereportInsider {
    width: 100%;
}
.caseReportItem {
    margin: 20px 0px;
}
.doubleitems {
    display: flex;
}

.casereportFinalButton {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin: 20px 0px;
    align-items: center;
}

.casereportFinalButton .backgroundButton {
    /* Primary blue */

    color: #4318ff;
    background: none;
    border: 1px solid #4318ff;
    border-radius: 4px;
}
.casereportFinalButton button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 24px;
    color: #4318ff;
    /* #4318FF */

    margin: 10px;
    background: #ffffff;
    border: 1px solid;
    box-sizing: border-box;
    border-radius: 4px;
}

.solidButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 24px;
    color: #f4f7fe !important;
    margin: 10px;
    background: #4318ff !important;
    box-sizing: border-box;
    border-radius: 4px;
}
.secondaryButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 24px;
    color: #181819 !important;
    margin: 10px;
    background: #dbd8e8 !important;
    box-sizing: border-box;
    border-radius: 4px;
}

.hollowButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 23px !important;
    color: #4318ff !important;
    background: none !important;
    border: 1px solid #4318ff !important;
    box-sizing: border-box;
    border-radius: 4px !important;
}

.casereportFinalButton .grey {
    /* #A3AED0 */
    border: none;
    background: #a3aed0;
    border-radius: 4px;
}
.active {
    position: relative;
}
.active::before {
    content: "";
    position: absolute;
    bottom: -7px;
    /* //-17px */
    left: -5px;
    height: 6px;

    background: #4318ff;
    border-radius: 6px;
    width: 110%;
}
.tabs li {
    cursor: pointer;
}
.topPadding {
    padding-top: 3%;
}

.viewLogs {
    grid-column: 2;
}

.sendMessage,
.viewLogs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 0px;
    color: #4318ff;
    font-size: 14px;
    /* #4318FF */
    background: #f4f7fe;
    border: 1px solid;
    border-color: #4318ff;
    border-radius: 4px;
    box-sizing: border-box;
}

.fileCard {
    width: 240px;
    padding: 10px;
    height: 72px;

    background: #ffffff;
    /* blue 300 */

    border: 1px solid #a3aed0;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
}
.fileName {
    margin-left: 4px;
}
.fileName p:nth-child(1) {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
}

.fileName p:nth-child(2) {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    /* blue 300 */

    color: #a3aed0;
}

.InfoContainer .item {
    box-shadow: 0px 1px 0px #eaedf3;
    padding: 20px;
    display: flex;

    align-items: center;
    width: 50%;
    justify-content: space-between;
}
.InfoContainer .item > * {
    width: 50%;
}

.InfoContainer .item div:nth-child(1) {
    margin-right: 5em;
}
.singleItem {
    margin: 2.5em 0;
    display: flex;
    width: 100%;
}
.caseremarks {
    width: 100%;
}
.singleItem h4,
.caseDetailsRight h4,
.caseReportRight h4,
.lable {
    font-family: "DM Sans";
    font-style: bolder;
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 133% */

    letter-spacing: -0.02em;

    /* Text */

    color: #1b2559;

    width: 35%;
}
.filer {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    display: flex;
    align-items: flex-end;
    font-feature-settings: "ss01" on;

    /* #1B2559 */

    color: #1b2559;
}
.caseRepDivider {
    height: 1px;
    /* margin-bottom: 0.5rem; */
    /* blue 300 */

    background: #a3aed0;
}
.caseDetailsLeft {
    width: 50%;
}
.caseReportLeft {
    padding-right: 20px;
}
.MainCaseReport {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.MainCaseReport > * {
    width: 50%;
}
.leftCCR {
    padding-right: 20px;
}
.ClosingReportTop {
    display: flex;
}
.ClosingReportTop > * {
    flex: 1 0 0;
}
.translationremark {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.translationremark > * {
    flex: 1 0 0;
}
.TranslatedRemark p {
    background: red;
}
.singleItem > span:nth-child(2) {
    width: 60%;
}
.singleItem > span:nth-child(2) * {
    width: 100%;
    text-align: left;
}
.singleItem > span:nth-child(2) *::after {
    margin-top: 8px;
    float: right;
}
.ReportFile {
    display: flex;
    flex-direction: column;
}
.sDragger {
    width: 100%;
    display: flex;
    align-items: center;
}
.sDragger > span {
    width: 90%;
}
.filerComp {
    background: rgba(163, 174, 208, 0.3);
    border-radius: 100px;
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
}
.filerComp a {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    padding: 10px;
    line-height: 21px;
    /* identical to box height, or 131% */

    /* #1B2559 */

    color: #1b2559;
}
.filerComp span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    /* identical to box height */

    font-feature-settings: "ss01" on;
    text-decoration-line: underline;
    cursor: pointer;

    /* blue 400 */

    color: #868cff;
}

.closingMainBox {
    display: flex;
    flex-direction: column;
    /* background: red; */
    height: 100%;
    width: 100%;
    margin-top: 2rem;
}
.leftMainBox {
    display: flex;
    width: 50%;
    flex-direction: column;
}
.lable {
    width: 90%;
    font-family: "DM Sans";
    font-weight: 900;
    font-size: 16px !important;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #1b2559 !important;
    margin-right: 2rem;
}
.rightMainBox {
    width: 50%;
}
.messageBox {
    /* margin-left: 5rem; */
    padding: 2rem 0;
    font-size: 16px;
}

.headingWrapper {
    /* background-color: #1b2559; */
    padding-top: 1rem;
    width: 100%;
}
.basicBox,
.variableBox {
    display: flex;
    /* background: #2b3674; */
    /* flex-direction: column; */
    width: 100%;
    margin-top: 1rem;
}
.leftBox {
    width: 50%;
    /* background-color: rebeccapurple; */
}
.rightBox {
    width: 50%;
    /* background-color: palegoldenrod; */
}
.variableBox {
    flex-direction: column;
}

.translateBtn,
.disabledBtn {
    /* display: flex; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 23px;
    /* width: 150px; */
    font-size: 14px;
    /* #4318FF */
    background: #f4f7fe;
    border: 1px solid;
    margin: 10px 5px;
    border-radius: 4px;
    box-sizing: border-box;
}
.dynamicVariablesBox {
    display: flex;

    justify-content: space-between;
}
.translateBtn {
    color: #4318ff;
    border-color: #4318ff;
}
.disabledBtn {
    color: #6e6e6e;
    border-color: #636363;
    cursor: not-allowed !important;
}

.mainWrapper {
    overflow: hidden;

    overflow-y: auto;
    height: 15rem;
}
.footerWrapper {
    justify-content: end;
    display: flex;
}
.label {
    color: #1b2559;
    font-size: 16px;
}
.rate {
    padding: "10px";
    margin-left: 15px;
}

.reason {
    font-size: 14px;
    display: grid !important;
}

.overlay {
    background: rgb(241 241 241 / 30%);
    position: absolute;
    width: 100%;
    height: 102%;
    top: -1.5rem;
    left: 0;
}

.reopened {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.generalReport {
    background-color: rgb(218 220 252);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
}
.ratingHeader {
    text-align: center;
}
.rating {
    padding: 12px;
    border-top: 0.1px solid #e2e5ed;
}
.errorMsg {
    color: red;
    margin-top: 5px;
}

.flexRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 20px 0px;
}
.versionAttachment {
    margin: 30px 0px;
}

.VersionFilterWrapper {
    position: absolute;
    top: -0.2rem;
    right: 1rem;
}

.caseReportContainer {
    font-size: 1.85rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;
}

.caseReportContainer span {
    display: inline-block;
    vertical-align: middle;
}

.caseReportContainer img {
    vertical-align: middle;
    width: 2rem;
}
.reopened img {
    width: 1.5rem !important;
}

.heading {
    vertical-align: middle;
    font-size: 1.85rem;
    font-weight: 600;
    color: #2b3674;
    display: flex;
    font-family: Inter;
    line-height: 24px;
}

.caseReportRow {
    padding: 2rem;
    display: grid;
    grid-template-columns: 20% 20%;
    justify-content: space-between;
}

.caseReportBody {
    font-family: Inter;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
    margin: 0 2rem 1rem 2rem;
    padding: 0.5rem;
}

.headingContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.audioContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
}
