.loginContainer {
    background-color: #f4f7fe;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 20px;
    height: 100%;
    width: 100%;
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    padding: 20px;

    line-height: 42px;
    /* or 105% */

    /* text-align: center; */
    letter-spacing: -0.02em;

    /* blue 600 */

    color: #2b3674;
}
.mainHeadingWrapper {
    text-align: center;
    padding: 0 2.5rem;
}
.loginContainer h2 {
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold !important;
    font-size: 2.5em;
    line-height: 45px;
    /* or 105% */
    text-align: left !important;
    letter-spacing: -0.02em;

    /* blue 600 */

    color: #2b3674;
}
.loginMain {
    background: #ffffff;
    /* elevation */
    min-height: 570px;

    padding: 40px 0;
    width: 460px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
}

.leftLogin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.rightLogin {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.loginDetails label {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height */
    margin-top: 1em;

    font-feature-settings: "tnum" on, "lnum" on, "ss01" on;

    /* text */
    text-align: left;

    color: #1b2559;
}

.loginDetails input {
    /* blue 100 */

    background: #f4f7fe;
    /* blue 600 */

    border: 0.3px solid #2b3674;
    border-right: none;
    box-sizing: border-box;
    border-radius: 5px 0px 0px 5px;
    width: 322px;
    height: 48px;

    padding: 15px;
}
.loginDetails input::placeholder {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;

    line-height: 22px;
    /* identical to box height */

    letter-spacing: 0.0275em;

    /* blue 300 */

    color: #a3aed0;
}
.loginDetails {
    margin-left: 4em;
    margin-top: 2em;
    margin-right: 4em;
    margin-bottom: 4em;
}
.forgetPassword {
    text-align: left;
    font-weight: 400;
    cursor: pointer;
    color: #4318ff;
}
.loginContainer button {
    width: 152px;
    height: 53px;

    /* Primary blue */
    background: #4318ff;
    border-radius: 4px;
    color: white;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    /* identical to box height, or 111% */

    /* Primary white */

    color: #f5faf5;
}
.loginMain a {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;

    line-height: 18px;
    /* identical to box height */

    letter-spacing: 0.0275em;
    font-feature-settings: "tnum" on, "lnum" on, "ss01" on;

    /* blue 300 */

    color: #a3aed0;
}
.gblBox {
    width: 236px;
    height: 105px;

    /* blue 400 */

    background: #868cff;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.06);
    border-radius: 10px;

    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    position: absolute;
    top: 30px;
    left: -30px;
    /* blue 600 */

    color: #2b3674;
}

.loginDetails label div {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.loginDetails label div span {
    user-select: none;

    height: 48px;
    width: 38px;
    background: #f4f7fe;
    border: 0.3px solid #2b3674;
    border-radius: 0px 5px 5px 0px;
    cursor: pointer;
    border-left: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.PwdError {
    color: red;
    font-size: 9px;
    padding: 0;
    margin: 0;
    line-height: 0px;
    text-align: left;
}
#passwordError {
    position: relative;
    border: 1px solid red;
}
#passwordError::after {
    background-color: white;
    position: absolute;
    bottom: -120%;
    color: red;
    content: "The password should be at least 8 characters long and contain at least one number, one uppercase and one lowercase letter, one special character.";
}

#usrError {
    position: relative;
    border: 1px solid red;
}
#usrError::after {
    background-color: white;
    position: absolute;
    bottom: -50%;
    color: red;
    content: "The username should be greater than 8 characters";
}
#passwordMatchingError {
    position: relative;
    border: 1px solid red;
}
.ForgotPassword {
    cursor: pointer;
    color: #4318ff;
}
.SignIn {
    cursor: pointer;
}
.EmailReset {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.EmailReset > * {
    margin: 1em;
    width: 70%;
}
.loading {
    background-color: rgb(175, 175, 175) !important;
    cursor: not-allowed;
}
