.mainContainer {
    border-top: 1px solid #e2e5ed;
    min-height: 60vh;
    background-color: #ffffff;
    border-radius: 20px;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 1%;
    margin-bottom: 1%;
}

.ButtonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
}

.containerCard {
    width: calc(100% - 5rem);
}

.dropdown {
    min-width: 200px;
}
