.item {
    background: #ffffff;
    /* green */
    margin: 0.5rem;
    border: 1px solid rgba(222, 215, 255, 1);
    /* dchbhd */
    min-width: 8em;
    height: 80%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-direction: column;
    box-shadow: 0px 0px 4px rgba(67, 24, 255, 1);
    border-radius: 16px;
    text-align: start;
    padding: 2rem 1rem 0.5rem 0.5rem;
}
.item h3 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 42px;
    /* identical to box height, or 124% */

    letter-spacing: -0.02em;

    /* Text */

    color: #1b2559;
    padding: 0;
    margin: 0;
}
.item p {
    margin: 0;
    padding: 0;
}
