// APP INIT CSS
html,
body,
.App,
#root {
    background-color: white;
    text-align: center;
    color: #2b3674;
    height: 100%;
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    width: 100%;
    overflow-x: hidden;
}
table {
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}
.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}
.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.MuiPaper-root {
    min-width: 10vw;
    overflow-x: scroll;
}
// Buttons css custom
.buttons {
    position: absolute;
    bottom: 4em;
    right: 4em;
    display: flex;
    flex-direction: row;
    width: 15em;
    justify-content: space-around;
}

.buttons button {
    padding: 9px 24px;
    color: #ffffff;
    width: 100px;

    /* #4318FF */
    display: flex;
    align-items: center;
    justify-content: center;
    background: #4318ff;
    border: 1px solid #0e5aca;
    box-sizing: border-box;
    border-radius: 4px;
    height: 42px;
}
.buttons .grey {
    /* #A3AED0 */
    border: none;
    background: #a3aed0;
    border-radius: 4px;
}
.grey-report {
    background-color: #e2e5ed;
    padding: 1em;
}
button {
    border: none;
}

input[type="text"]::placeholder {
    /* Firefox, Chrome, Opera */
    text-align: left;
}
.divider {
    border-top: 3px solid #bbb;
}

ul {
    list-style: none;
}
button {
    cursor: pointer;
}
.required:after {
    content: " *";
    color: red;
}

.backBtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.margin-btn {
    margin: 30px;
}
.flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
