/* Policy.module.css */
.rowStyle {
    background-color: white;
    margin: 0 2rem 2rem;
    padding: 1rem;
    border-radius: 16px;
    min-height: 70vh;
}

.innerRowStyle {
    overflow-y: auto;
    padding: 10px;
    text-align: start;
}

.colStyle {
    overflow-y: auto;
    height: 90vh;
}

.headerRowStyle {
    background-color: #e2e5ed;
    border-radius: 16px 16px 0 0;
    height: 48px;
    border-top: 1px solid black;
    padding: 1rem 0rem 1rem 1rem;
    text-align: start;
    margin: 0 0 1rem 0;
}

.headerStyle {
    text-align: start;
    color: #333;
    margin-bottom: 20px;
}

.policyListStyle {
    padding-left: 20px;
    margin-left: 1rem;
}

.policyItemStyle {
    margin-bottom: 10px;
    cursor: pointer;
}

.policyItemStyle a {
    color: black;
}
.policyLinkStyle {
    text-decoration: none;
    color: black;
    font-weight: normal;
}

.policyLinkActiveStyle {
    text-decoration: none;
    color: blue;
    font-weight: bold;
}
.textStyle {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
    margin-left: 10px;
}
