.container {
    position: relative;
    background-color: #e7e9f0;
    margin: 0;
    height: calc(100vh - 55px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}

.backgroundImage {
    position: absolute;
    right: 0;
    top: 0;
    width: "30%";
    z-index: 0;
}
.backgroundImagerorate {
    position: absolute;
    left: 0;
    bottom: 0;
    width: "30%";
    z-index: 0;
    transform: rotate(180deg);
}
.formContainer {
    width: 40%;
    margin: auto;
    text-align: center;
    position: relative;
    z-index: 9;
}

.formContent {
    background: #ffffff;
    width: 100%;
    margin: auto;
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
}

.formHeader {
    text-align: left;
}

.welcomeText {
    color: #707eae;
}

.titleText {
    font-size: 34px;
    color: #2b3674;
    font-weight: 700;
}
