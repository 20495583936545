.parentBox {
    height: calc(100% - 3rem);
    background-color: white;
    padding: 16px 20px;
    border-radius: 12px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
}
.broadcastHeading {
    text-align: start;
    margin-bottom: 1rem;
    color: #2b3674;
}

.broadcastContainer {
    width: calc(100% - 5rem);
    padding: 1rem 2rem 2rem 2rem;
}
