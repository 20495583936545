.ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: rgba(156, 72, 195, 0.133);
}

.ant-table-selection-col {
    width: 75px;
}
span.ant-checkbox {
    transform: scale(1.2);
    border: 1px solid;
    border-radius: 2px;
}
