.heading {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 0 2rem 2.5rem;
}
@media only screen and (max-width: 600px) {
    .TableContainer {
        grid-template-rows: 20% 80%;
        grid-template-columns: 100%;
    }
}
.overlay {
    background: rgb(241 241 241 / 30%);
    position: absolute;
    width: 100%;
    height: 102%;
    top: -1.5rem;
    left: 0;
}

.TableContainer {
    display: grid;
    overflow: hidden;
}
