.incentiveContainer {
    font-family: Inter;
    display: grid;
    color: #1b2559;
    width: 100%;
}
.childbox {
    display: grid;
    color: #1b2559;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    overflow: scroll;
}
.incentiveContainer p {
    margin: 0;
}
.left {
    display: flex;
    flex-direction: column;
}
.left > div:nth-child(1) {
    height: 8em;
    padding: 10px;
}

.left > div:nth-child(2) {
    height: 13em;
    margin: 10px;
}
.left > div:nth-child(3) {
    min-height: 33em;
}
.left > div:nth-child(4) {
    height: 33em;
}

.left > div:nth-child(5) {
    height: 35em;
}
.right > div:nth-child(1) {
    height: 30em;
    padding: 12px;
}
.right > div:nth-child(2) {
    height: 20em;
    padding: 12px;
}
.dateWinner {
    display: flex;
    flex-direction: row;
    color: rgb(0, 0, 0);
}
.dropdown {
    min-width: 250px;
}
.dateWinner > div:nth-child(1) {
    /* flex-basis: 50%; */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 15px;
    justify-content: flex-start;
    background: white;
    border-radius: 20px;
    color: black;
}

.dateWinner .Winner {
    color: #000000;
    display: flex;
    padding: 12px;
}
.dateWinner .Winner > * {
    flex-basis: 50%;
}
.dateWinner .Winner > div:first-child {
    text-align: left;
    font-size: 18px;
    line-height: 150%;
    /* or 30px */

    letter-spacing: 0.022em;
    font-feature-settings: "ss0";
}

.dateWinner .Winner .factroyWinner {
    color: rgb(175, 138, 138) !important;
    text-align: left;
    padding: 15px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
    /* identical to box height, or 124% */

    letter-spacing: -0.02em;

    color: #ffffff;
}

.dateWinner .image {
    margin: 10px 10px;
    background: #f4f7fe;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 50%;
}
.caseCount {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: #ffffff;
    border: 1px solid rgba(67, 24, 255, 0.1);
    border-radius: 16px;
    width: 50%;
    padding: 18px;
    height: fit-content;
}
.overlay {
    background: rgba(192, 192, 192, 0.3);
    position: absolute;
    border-radius: 16px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.caseCount > div:first-child {
    text-align: left;
    align-self: flex-start;
    align-items: baseline;
    display: flex;
}
.caseCount > div > h5 {
    font-weight: 700;
    font-size: 34px;
    line-height: 42px;
    padding-right: 6px;
    /* identical to box height, or 124% */
    margin-bottom: 2px;
    letter-spacing: -0.02em;
}
.caseCount > div:first-child > div {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
}

.caseCount > div:nth-child(2) {
    /* border: 1px solid #05cd99; */
    /* dchbhd */
    display: flex;
    width: 100%;
    justify-content: end;

    /* box-shadow: 0px 0px 4px #05cd99; */
    border-radius: 16px;
}

/* .caseCount > div:nth-child(3) {
    border: 1px solid #ffb547;
    box-shadow: 0px 0px 4px #ffb547;
    border-radius: 16px;
}
.caseCount > div:nth-child(4) {
    border: 1px solid #ff0000;
    box-shadow: 0px 0px 4px #ff0000;
    border-radius: 16px;
}
.caseCount > div:nth-child(5) {
    border: 1px solid #ff0000;
    box-shadow: 0px 0px 4px #ff0000;
    border-radius: 16px;
} */
.download {
    background: #4318ff;
    border: 1px solid #0e5aca;
    border-radius: 4px;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 198px;
    height: 42px;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: space-evenly;
    color: #ffffff;
}
.guidlines {
    background: transparent;
    border: 1px solid rgba(67, 24, 255, 1);
    border-radius: 4px;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 198px;
    height: 42px;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: space-evenly;
    color: rgba(67, 24, 255, 1);
    margin-right: 1rem;
}

.boxOne {
    /* margin-top: 2rem; */
    margin: 2rem 0 2rem 2rem;
    /* background-color: #0e5aca; */
    display: flex;
    gap: 3rem;
}
.leftBoxOne {
    /* background-color: #05cd99; */
    width: 50%;
}
.leftBoxOne > h1 {
    text-align: start;
    /* padding-left: 2rem; */
}
.wrapperBoxOne {
    display: flex;
    /* justify-content: space-between; */
}
.eligbleHeading,
.dateBox {
    display: flex;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    /* width: 45%; */
    background-color: white;
    border-radius: 16px;
    border: 1px solid rgba(67, 24, 255, 1);
    color: #000000;
}
.eligbleHeading {
    margin-left: 1rem;
    height: 3rem;
}
.editCriteria {
    margin-left: 1rem;
    height: 3rem;
    display: flex;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: rgba(67, 24, 255, 1);
    border-radius: 16px;
    border: 1px solid rgba(67, 24, 255, 1);
}
.dateBox2 {
    display: flex;

    padding-left: 30px;

    /* width: 45%; */
    background-color: #868cff;
    border-radius: 16px;
    border: 1px solid rgba(67, 24, 255, 1);
    color: #000000;
}
.rightBoxOne {
    /* background-color: pink; */
    height: 10rem;
    width: 50%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.btnWrapperBoxOne {
    display: flex;
    padding-right: 1rem;
    /* background-color: #f4f7fe; */
    justify-content: end;
}
.btnWrapperBoxOne ~ button {
    padding: 0 1rem;
}
.btnBoxOne {
    padding: 01rem;
    margin-left: 1rem;
}
.eligbleHeading > p {
    font-size: 16px;
    margin: 0 1rem;
}
.boxTwo {
    display: flex;
    /* width: 100%; */
    margin: 0 2rem;
    gap: 2rem;
    justify-content: space-around;
    /* margin: 2rem 0; */
}
.winner {
    background-color: rgba(134, 140, 255, 1);
    border-radius: 16px 0 0 16px;
    text-align: center;
    max-height: 120px;
    overflow: auto;
}
.winner > h1 {
    font-size: 24px;
    margin: 0;
    padding: 0.5rem 0;
    color: #f4f7fe;
}
.chartOne,
.chartTwo,
.chartThree {
    display: flex !important;
    height: -webkit-fill-available;
    margin: 2rem;
    height: 95%;
}

.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}
.calenderSymbol {
    /* padding-top: 35px; */
    margin: auto;
    padding-right: 20px;
}

.rangePickerStyle {
    padding-left: 20px;
    /* padding-top: 1.8rem; */
    /* //padding-right: 2rem; */
    background-color: white;
    border-radius: 16px;
    width: 100%;
}
