.customHeader {
    display: flex;
    justify-content: space-between;
    min-height: 10%;
    padding: 0px 0px 1rem;
    margin-bottom: 1rem;
}

.datePanel {
    display: flex;
    cursor: pointer;
    font-size: 20px;
    width: 100%;
    justify-content: space-around;
}

.monthYearPanel {
    display: flex;
    flex-direction: column;
}

.datePickerPanel {
    display: flex;
    flex-direction: column;
    width: 25%;
    justify-content: center;
}
