.flex {
    display: flex;
}
.flexr {
    display: flex;
    flex-direction: row;
}
.flexc {
    display: flex;
    flex-direction: column;
}
.diabledPointer {
    cursor: no-drop;
}
.primaryButton {
    /* display: flex; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 24px;
    color: #f4f7fe !important;
    margin: 10px 5px;
    background: #4318ff !important;
    box-sizing: border-box;
    border-radius: 4px;
}
.primaryButton:hover {
    background: #6641fd !important;
}
.secondaryButton {
    /* display: flex; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 23px !important;
    color: #4318ff !important;
    background: none !important;
    border: 1px solid #4318ff !important;
    box-sizing: border-box;
    border-radius: 4px !important;
    margin: 10px 5px;
}
.secondaryButton:hover {
    color: #6641fd !important;
    border: 1px solid #6641fd !important;
}
.error-row {
    background-color: #ffcccc;
}
.primaryBorderStyle {
    border-radius: 12px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
}
.required input:after {
    content: "*";
}

.caseRepDivider {
    height: 1px;
    background: #a3aed0;
}

.centerContent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.redBackground {
    background-color: rgb(248, 199, 199);
}
