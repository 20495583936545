@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500;700&family=Poppins:wght@400;500;600&display=swap");

:root {
    --main-bg-color: #b4b4bb;
    --main-color: #1b4ea3;
    --secondary-color: #9dc2ff;
    --border-color: #2979ff;
}

@import "./styles/init.scss";
@import "./styles/other-override.scss";
@import "./styles/antd-override.scss";
@import "./styles/printing.scss";
