.innerDropdownMainBox {
    display: flex;
    padding-left: 30px;
    background-color: #868cff;
    border-radius: 16px;
    border: 1px solid rgba(67, 24, 255, 1);
    color: #000000;
}
.imageWrapper {
    margin: auto;
    padding-right: 20px;
}

.placeholder {
    padding-left: 20px;
    display: flex;
    height: 48px;
    margin: auto;
    background-color: white;
    border-radius: 16px;
    width: 100%;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    color: #959595;
}

.dropdownMainBox {
    position: relative;
    z-index: 8;
}
.dropdownBox {
    position: absolute;
    width: 100%;
    z-index: 7;
    background-color: #fff;
    padding: 22px;
    text-align: start;
    border: 1px solid rgba(67, 24, 255, 1);
    border-radius: 12px;
    border-top: 0;
}
.flex {
    display: flex;
}
.parentItem {
    width: 100%;
    flex-wrap: wrap;
    overflow-y: auto;
    max-height: 250px;
}

.itemData {
    width: 50%;
    min-width: 50%;
    text-align: start;
    align-items: center;
    display: flex;
    margin-bottom: 10px;
    text-transform: capitalize;
    /* flex-direction: column; */
}
.itemData > label,
.innerDropdownBox > label {
    padding-left: 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    white-space: nowrap;
}
.innerDropdownBox {
    align-items: center;
    display: flex;
}

.itemData > input,
.innerDropdownBox > input {
    min-width: 20px;
    min-height: 20px;
}

.truncate {
    overflow: hidden;
}
.placeholderWrapper {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    white-space: nowrap;
    margin: 0 1rem;
    width: 100%;
    text-transform: capitalize;
    color: black;
}

/* ------css for multiSelectDropDown-------- */
.dropdownSelect {
    /* width: 10rem;
    height: 5rem; */
    overflow: hidden;

    position: relative;
    cursor: pointer;
    height: 30px;
    min-height: 32px;
    flex: auto;
    max-width: 100%;
    border: 1px solid rgba(67, 24, 255, 1);
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 0.25rem;
    box-shadow: 0px 2px 4px rgba(90, 91, 106, 0.24),
        0px 1px 2px rgba(58, 58, 68, 0.24);

    /* padding: 18px 0; */
    padding: 0.25rem 0.75rem !important;
}
.dropSelect {
    position: absolute;
    top: 50%;
    right: 11px;
    display: flex;
    align-items: center;
    height: 12px;
    margin-top: -6px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    line-height: 1;
    text-align: center;
    pointer-events: none;
}
.flexRow {
    flex-direction: row;
}
.itemDataMain {
    width: 100%;
    min-width: 50%;
    text-align: start;
    display: flex;
    margin-bottom: 10px;
    text-transform: capitalize;
    flex-direction: column;
    align-items: flex-start;
}
.subItem {
    padding: 0 20px;
}
.disabledField {
    background-color: #dddddd;
    cursor: no-drop;
    color: #dddddd;
}
.buttonContainer {
    display: flex;
    align-items: end;
    justify-content: end;
}
