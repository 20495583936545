.TableData {
    /* grid-column: 1/3; */
    font-family: Inter;
    border-radius: 20px;
    min-height: 70vh;
    /* max-height: fit-content; */
    /* overflow-x: scroll; */
    /* overflow: auto; */
    /* height: 70vh; */
    background-color: white;
    margin-left: 30px;
    position: relative;
    margin-right: 30px;
    min-width: 90vw;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
    margin-bottom: 2rem;
}

.tableContainer {
    overflow: auto !important;
}

.button {
    margin-right: 10px;
    margin-bottom: 5px;
    width: 71px;
    height: 30px;
    background-color: #4318ff;
    color: whitesmoke !important;
    border: none;
    padding: 3px;
    font-size: 10px;
    border-radius: 4px;
}

.buttonLog {
    /* #4318FF */
    width: 71px;
    height: 30px;
    border: 1px solid #4318ff;
    box-sizing: border-box;
    border-radius: 4px;
    color: #4318ff;
    padding: 3px;
    font-size: 10px;
    border-radius: 4px;
    background-color: transparent;
}

.NocasesDiv > * {
    margin-top: 0.2em;
    margin-bottom: 0.4em;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 25px;
    line-height: 150%;
    /* or 45px */
    letter-spacing: 0.0275em;
    font-feature-settings: "tnum" on, "lnum" on, "ss01" on;

    /* #1B2559 */

    color: #1b2559;
}
.caseNumber {
    color: #1b2559;
    cursor: pointer;
}
.flag::before {
    position: absolute;
    top: 0;
    left: 2px;
    content: "";
    height: 100%;
    width: 2px;
    background-color: red;
}
.NocasesDiv p {
    font-size: 15px;
}
.NocasesDiv {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.PriorityDisplay {
    left: 20px;
    display: flex;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    /* identical to box height, or 143% */
    align-items: center;
    width: max-content;
    position: absolute;
    bottom: 0.5rem;
    /* #1B2559 */
    color: #1b2559;
}

.purple-row {
    background-color: #868cff !important;
}

.gray-row {
    background-color: #bebebe !important;
}

.white-row {
    background-color: white !important;
}
