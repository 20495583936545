.box {
    display: flex;
    padding: 1.6rem 2.3rem;
    background-color: white;
    border-radius: 16px;
    border: 1px solid rgba(67, 24, 255, 1);
    color: #000000;
}

.reportesContainer {
    display: grid;
    width: 100%;
    margin: 2rem;
}

.caseCountBox {
    padding: 18px;
    background: #ffffff;
    border-radius: 16px;
}
.mg2 {
    margin: 1rem 0;
}
.textLeft {
    text-align: left;
}
.container {
    gap: 4;
    margin: 1rem 0;
    border-radius: 16px;
    width: 100%;
    text-align: left;
    font-family: "DM Sans";
    font-weight: 700;
    z-index: 1;
}

.download {
    background: #4318ff;
    border: 1px solid #0e5aca;
    border-radius: 4px;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 198px;
    height: 42px;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: space-evenly;
    color: #ffffff;
    margin-left: auto;
}

.blueOutlineBox {
    padding-left: 20px;
    padding-top: 1.8rem;
    background-color: white;
    border-radius: 16px;
    width: 100%;
}
.rangePickerStyle {
    padding-left: 20px;
    padding: 4px 0;
    background-color: white;
    border-radius: 16px;
    width: 100%;
}
