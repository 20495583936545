.TableContainer {
    border-radius: 2% !important;
    overflow: hidden;
}

.TableContainer {
    padding: 1% 1%;
    background-color: #ffffff;
    border-radius: 20px !important;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
    margin: 2%;
    width: 100%;
}

.customBox {
    margin: 0 2rem;
    border: 1px solid #4318ff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
}

.container {
    background-color: white;
    padding: 1rem;
    border-radius: 12px;
    min-height: 70vh;
}

.header {
    border-bottom: 1px solid #bebebe;
    padding: 0 0 1rem 0;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}

.titleCol {
    text-align: justify;
}

.backLink img {
    cursor: pointer;
}

.title {
    text-align: justify;
    margin-left: 1rem;
}

.textArea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #1b2559;
    height: 2.5rem;
}

.input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #1b2559;
    height: 2.5rem;
}
.subheading {
    font-weight: 700;
    font-size: 16px;
    font-style: normal;
    color: #1b2559;
    text-align: start;
    padding-left: 5px;
    margin-bottom: 1rem;
}
.expiringSoonRow {
    background-color: #ffcccc; /* Light red background */
}
