.WelcomeHeading {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
}
.WelcomeHeading h1 {
    color: #2b3674;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1em;
    /* identical to box height, or 124% */
    letter-spacing: -0.02em;
    margin: 0;
}

.WelcomeHeading h2 {
    color: #707eae;
    font-weight: 600;
    font-size: 1rem;
}

.WelcomeHeading p {
    color: #707eae;
}
