.ant-table-column-title {
    z-index: unset !important;
}

.ant-table-body {
    overflow: auto !important;
}

.ant-table-thead > tr > th {
    background-color: #eaeaea !important;
    font-size: 13px !important;
}

.ant-table-column-title {
    color: #0a0a0a;
}

.ant-table-wrapper {
    margin-left: 20px;
    margin-right: 20px;
}

.ant-table-tbody > tr > td {
    border-bottom: 1px solid #e2e5ed !important;
    hyphens: inherit;
    text-align: left;
    color: #1b2559;
}
.ant-message {
    bottom: 20px;
    top: initial;
}

.ant-table-wrapper {
    overflow: auto;
    background: #ffffff;
}
td.ant-table-cell {
    font-size: 12px;
    word-break: break-word;
    hyphens: auto;
}
.ant-select-selector {
    height: 2.5rem !important;
    padding: 0.25rem 0.75rem !important;
    border-radius: 0.25rem !important;
    color: var(--color100);
    background: var(--white);
    box-shadow: var(--shadow2dp);
}
.ant-radio-group {
    width: 100%;
    display: flex !important;
    justify-content: space-around;
}
.antd-calendar input::placeholder {
    color: rgb(0, 0, 0) !important;
}
.antd-calendar input {
    color: rgb(0, 0, 0) !important;
}
.antd-dropDate .ant-picker-panel-container * {
    background: #ffffff;
    color: #1b2559 !important;
    border: #4318ff !important;
}
.antd-dropDate .ant-picker-cell {
    color: white;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: #ffffff !important;
}
.ant-picker-range-separator {
    display: none;
}
.site-page-header {
    border: 1px solid rgb(235, 237, 240);
    color: #2b3674;
    margin: 20px;
}
.ant-page-header {
    margin-bottom: 20px !important;
}
.ant-upload-hint {
    margin: 10px !important;
}
#CTdrop .ant-select {
    width: 60%;
}
.ant-upload-text {
    text-align: center !important;
}
.ant-table-cell-row-hover {
    background-color: #d9d1ff !important;
}

.ant-form-item-label > label {
    font-family: "DM Sans" !important;
    font-style: bold !important;
    height: auto !important;
}

.ant-form-item-control-input-content {
    display: flex-end;
    width: 100%;
}

.ant-form-item > div {
    padding-right: 3%;
}

.ant-form-item-label
    > label.ant-form-item-required:not(
        .ant-form-item-required-mark-optional
    )::before {
    content: "" !important;
}

.ant-form-item-label
    > label.ant-form-item-required:not(
        .ant-form-item-required-mark-optional
    )::after {
    content: "*" !important;
    color: red;
}

.ant-form-item-label {
    width: 35% !important;
    white-space: normal !important;
    text-align: left !important;
}

.ant-form-item {
    align-items: flex-start !important;
}
// .ant-form-item-label
//   > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
//   display: none !important;
// }
// .ant-form-item-label
//   > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
//   display: inline-block;
//   margin-right: 4px;
//   color: #ff4d4f;
//   font-size: 14px;
//   font-family: SimSun, sans-serif;
//   line-height: 1;
//   content: "*";
// }
// .ant-form-item-label > label {
//   margin-right: 2.5rem;
// }

//css for GuidelinesModal comp
.guidelineModal .ant-modal-content,
.guidelineModal .ant-modal-header {
    border-radius: 20px;
}
.guidelineModal .ant-modal-title {
    font-size: 24px;
}
.guidelineModal .ant-modal-body {
    font-size: 18px;
}
//adjusting height of ct variable box input textarea
.ant-input-textarea-show-count > .ant-input {
    height: auto !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #f4f7fe !important;
}

.ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
    color: #4318ff !important;
}
.ant-menu-inline .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-vertical .ant-menu-item:after {
    border-right: 3px solid #4318ff !important;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu-item-active a:hover,
.ant-tooltip-open {
    color: #4318ff !important;
}

.ant-form-item-label > label {
    font-family: DM Sans !important;
    font-weight: bold !important;
    font-size: 16px !important;
    font-style: normal !important;
    color: #1b2559 !important;
}

.ant-table-row:hover {
    cursor: pointer;
}
.ant-tabs-nav-list :active {
    color: #4318ff !important;
}

.ant-tabs-tab.ant-tabs-tab-active :active {
    color: #4318ff !important;
}
.ant-tabs-tab.ant-tabs-tab-active :hover {
    color: #4318ff !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #4318ff !important;
}

.ant-tabs-ink-bar {
    height: 3px !important;
    border-radius: 1px !important;
    background: #4318ff !important;
}

.ant-tabs-nav-list {
    margin-left: 1.5% !important;
    justify-content: space-between !important;
}
.ant-tabs-nav-list :hover {
    color: #4318ff !important;
}

h4.ant-typography,
div.ant-typography-h4,
div.ant-typography-h4 > textarea,
.ant-typography h4 {
    white-space: initial;
}

.ant-card-meta {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10%;
    color: #2b3674;
}

.ant-modal-content {
    border-radius: 20px !important;
    border: 1px solid #4318ff !important;
}

.ant-modal-title {
    border-bottom: 1px solid #b1a0a060;
    padding-bottom: 12px;
}
td.ant-table-column-sort {
    background: initial !important;
}
td.ant-table-column-sort:hover {
    background: #d9d1ff !important;
}
td.sorted-column.ant-table-column-sort {
    background: initial !important;
}

td.sorted-column.ant-table-column-sort:hover {
    background: #d9d1ff;
}
:where(.css-djtmh8).ant-table-wrapper
    .ant-table-tbody
    .ant-table-row.ant-table-row-selected
    > .ant-table-cell {
    background: #d9d1ff;
}
//based on new design of landing page
.ant-table-thead > tr > th:first-child {
    border-top-left-radius: 20px !important;
    border: 0;
}

.ant-table-thead > tr > th:last-child {
    border-top-right-radius: 20px !important;
}
.ant-table.ant-table-bordered > .ant-table-container {
    border: 0 !important;
}
.ant-tabs-tab {
    font-size: 17px !important;
}
.ant-table table {
    border-spacing: 1px !important;
}
.ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table {
    border-top: 0 !important;
}

//holiday calender
.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    justify-content: flex-end;
}
.ant-picker-calendar-header {
    display: flex;
    justify-content: flex-end;
    padding: 12px 0;
    border-bottom: 1px solid #c9c9c9;
    margin-bottom: 1rem;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    margin-right: 0px !important;
    margin-left: 0px !important;
    padding-top: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
}

.ant-btn-icon-only {
    height: 16px !important;
}

.ant-modal-body {
    padding: 24px;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
    overflow-y: auto;
    overflow-x: hidden;
}
.ant-picker-calendar-header .ant-picker-calendar-month-select {
    min-width: 78px !important;
    margin-left: 8px;
}

//side menu icon css
.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
    min-width: 20px !important;
}

.ant-select-selector {
    height: fit-content !important;
    overflow-y: auto !important;
    max-height: 100px !important;
}

/* Custom CSS to remove active link styling for Ant Design NavLink */
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent !important;
}
.ant-checkbox-wrapper {
    padding: 10px !important;
}
.ant-rate {
    display: flex !important;
}
.ant-checkbox-group {
    display: grid !important;
}

#report .custom-tabs .ant-tabs-nav-list {
    display: flex !important;
}

#report .custom-tabs .ant-tabs-tab {
    flex: 1 !important; /* This ensures that each tab takes up equal space */
    display: flex !important;
    justify-content: center !important; /* Center the tab title */
    text-align: center !important; /* Ensure the text is centered as well */
}

#report .custom-tabs .ant-tabs-nav::before,
#report .custom-tabs .ant-tabs-ink-bar {
    width: var(--custom-ink-bar-width) !important;
    left: 0 !important;
    height: 6px !important;
    border-radius: 4px !important;
}

/* Style for the custom ink bar that stretches to the furthest visited tab */
#report .custom-tabs .ant-tabs-nav-list::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: #1890ff; /* Your desired highlight color */
    transition: width 0.3s ease-out;
}

/* Calculate the width based on the furthest visited tab */
// #report .custom-tabs .ant-tabs-nav-list {
//     position: relative;
// }
.ant-tour-inner {
    width: 425px;
}

:where(.css-dev-only-do-not-override-djtmh8).ant-table-wrapper
    .ant-table-tbody
    .ant-table-row.ant-table-row-selected
    > .ant-table-cell {
    background-color: #ddd7fa !important;
}

.ant-dropdown .ant-table-filter-dropdown .ant-dropdown-menu {
    text-align: left;
}
:where(.css-dev-only-do-not-override-djtmh8).ant-tabs .ant-tabs-extra-content {
    margin-bottom: 60px;
}

.ant-form-item-control-input {
    margin-bottom: 0.5rem;
}

.ant-picker-ranges li.ant-picker-now {
    display: none !important;
}
.ant-layout {
    height: max-content;
}

.ant-tabs,
.ant-tabs-content,
.ant-tabs-tabpane-active,
.ant-tabs-tabpane-active > .ant-form,
.ant-table-wrapper,
.ant-spin-nested-loading,
.ant-spin-container {
    height: 100% !important;
}

.ant-row-space-around {
    height: calc(100% - 5rem);
}

.ant-spin-nested-loading > .ant-spin-container > .ant-table {
    height: calc(100% - 4rem);
}

.ant-picker-ranges li.ant-picker-now {
    display: none !important;
}

.ant-layout:has(#offlineBody) {
    height: 100% !important;
}

#caseReportTabContainer .ant-tabs-content {
    overflow-y: scroll;
}

#offlineBody > .ant-tabs-tabpane {
    display: none;
}
.draftModalContainer > .ant-row-start {
    width: 60%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
    margin-bottom: 2rem;
}

.draftModalContainer > .ant-row-start > .ant-col > .ant-select {
    width: 100%;
}

.modalStyle .ant-modal-content {
    padding: 0 !important;
}

.modalStyle .ant-picker-range, .modalStyle .ant-select-selector {
    height: 32px !important;
    width: 18.2rem !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    box-shadow: 0px 2px 4px rgba(90, 91, 106, 0.24),
    0px 1px 2px rgba(58, 58, 68, 0.24);
    
}

.modalStyle .ant-select-selection-wrap {
    overflow-y: hidden;
    height: 100%;
}
