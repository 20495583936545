.ButtonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
}
.ButtonCard {
    font-family: "Inter";
    font-weight: 600;
    width: 268px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 20px;
    margin: 10px;
    background-color: white;
    color: #1b2559;
    transition: all 0.3s ease-in-out;
}

.ButtonCard span {
    margin-left: 20px;
    color: #1b2559;
    font-size: 16px;
    font-weight: 600;
}
.QCButtonContainer {
    display: flex;
    margin-right: 10em;
}
.ButtonCard span.value {
    font-size: 18px;
}

.dropdown {
    min-width: 250px;
}
