// Setproduct Override

._view_smooth__3xG6s {
    background: red;
}

// Modal React Override

.ReactModal__Overlay .ReactModal__Overlay--after-open {
    z-index: 10;
}
// AG table attempt Override

.ag-theme-alpine .ag-ltr .ag-cell {
    text-align: left;
}
.ag-header-cell-text {
    color: #a3aed0;
}

.ant-message {
    top: 0 !important;
}

textarea:read-only {
    cursor: default;
}
