.heading {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dashboardLayout {
    height: 100%;
}

.dashboardRow {
    padding: 2rem 2rem 1rem;
    display: flex;
    justify-content: space-between;
}

.dashboardBody {
    font-family: Inter;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
    margin: 0 2rem 0rem 2rem;
    padding: 0.5rem;
    height: 70vh;
}
