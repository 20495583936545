.casereportContainer {
    background-color: #f4f7fe;
    min-height: 120vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    color: #1b2559;
}
.casereportContainer textarea {
    background: #ffffff;
    /* blue 400 */

    border: 1px solid #868cff;
    border-radius: 4px;
}
.mainContainer {
    background-color: #f4f7fe;
    height: fit-content;
    width: 90vw;
}
.bottomContainer {
    /* White */
    padding: 20px;
    border-radius: 20px;
    box-shadow: 10px;
    position: relative;
    background: #ffffff;
    /* line2 */
    height: 100%;
    border: 1px solid #dfe3e9;
}
.header {
    padding: 20px 0;
    margin-top: 2em;
    background-color: #f4f7fe;
    font-family: Inter;
    color: #2b3674;
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
}
.headerRight {
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.header h4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 18%;
    justify-content: space-between;
}
.header h4 img {
    cursor: pointer;
    width: 34px;
    height: 34px;
}
.casereportContainer h4 {
    margin: 0;
}
.topButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 0px;
    color: #4318ff;
    width: 150px;
    font-size: 14px;
    /* #4318FF */
    background: #f4f7fe;
    border: 1px solid;
    border-color: #4318ff;
    border-radius: 8px;
    box-sizing: border-box;
}
