.mainDiv {
    display: flex;
    /* justify-content: space-between; */
}
.mainDivTitle {
    width: 30%;
    /* background-color: aqua; */
    text-align: start;
    padding: 1rem 0;
}
.mainDivContent {
    padding: 1rem 0;
}
.msgPreviewMain {
    width: 75%;
    display: flex;
}
/* .msgPreviewMain:first-child {
    background-color: aqua;
    width: 20%;
} */
.filterWrapper {
    width: 75%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
    margin-bottom: 2rem;
}

.valueBoxWrapper {
    width: 80%;
}
.confimButton {
    margin: 1rem;
    background-color: #d6e0ff;
    width: 232px;
    border-radius: 4px;
    border: 1px solid;
    padding: 10px 9px;
    font-weight: 500;
}
.modalContent {
    text-align: start;
}

.modalHeader {
    text-align: start;
    font-size: 22px;
}

.tabContainer {
    height: 100%;
}
