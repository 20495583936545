.rightNavbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
    width: 25em;
}
.navbarRole {
    font-family: DM Sans, sans-serif;
    font-size: 1.5em;
    font-weight: 500;
    align-items: center;
    padding-right: 1em;
    padding-top: 1em;
    display: flex;
    color: #1b2559;
}
.rightNavbar p {
    margin: 0 5%;
}
.leftNavbar {
    margin-left: 1.5%;
}
.loginLogo {
    width: 96vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.centerNavbar {
    align-items: center;
}

.login {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 8em;
}

.dropdown {
    background: #fafbfb !important;
    color: black !important;
    border: 1px solid #e6e9ed !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
}
.expandMore {
    position: relative;
    cursor: pointer;
}
.dropMenuUserProfile {
    position: absolute;
    top: 30px;
    right: 10px;
}

.proheader {
    width: 40vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ModalProfile form .left div,
.ModalProfile form .right div {
    margin: 20px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    padding: 5px;
}
.ModalProfile form label {
    margin-right: 20px;
}

.ModalProfile form {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.saveProfileChanges {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 120px;
}
.ProfilePic {
    display: flex;
    height: 60%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.ModalProfile button {
    margin: 15px;
    width: 88.36px;
    font-size: 14px;
    height: 30.88px;
    color: #fcfcfc;
    /* Primary blue */

    background: #4318ff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
    border-radius: 3.43137px;
}
#idButton {
    width: 120px;
}
#idButtonRemove {
    color: black;
    background: #fcfcfc;
    /* green */

    border: 0.857843px solid #678983;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6.86275px;
}
.AccHeader {
    text-align: left;

    /* green */

    border-bottom: 0.857843px solid #678983;
    box-sizing: border-box;
}
.accItem {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 2em;
}
.passwordChange {
    position: absolute;
    width: fit-content;
    bottom: -20px;
    right: -20px;
}

.accItem label {
    margin-right: 30px;
}
.closer {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
.ProfilePic p {
    margin: 5px;
    font-style: italic;
}
.overlay {
    background: rgba(192, 192, 192, 0.3);
    position: absolute;
    border-radius: 16px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    background: white;
    height: 64px;
    line-height: 64px;
    border-bottom: 1px solid #f0f0f0;
}

.dropMenuUserProfile {
    z-index: 1051 !important;
}

.leftSection {
    flex: 1;
    display: flex;
    align-items: center;
}

.centerSection {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rightSection {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.userProfile {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.userInfo {
    display: flex;
    align-items: center;
    gap: 8px;
}

.expandMore {
    display: flex;
    align-items: center;
    margin-left: 8px;
}

.loginHeader {
    display: flex;
    align-items: center;
    padding: 0 24px;
    background: white;
    height: 64px;
    border-bottom: 1px solid #f0f0f0;
}

.loginLogo {
    display: flex;
    align-items: center;
}
