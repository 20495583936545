.form {
    text-align: left;
}

.infoIcon {
    margin-left: 8px;
    cursor: pointer;
}

.translationTextArea {
    background-color: #f0f0f0;
    margin-top: 8px;
}

.large-rate .ant-rate-star {
    font-size: 2.7rem;
}
