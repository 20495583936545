@media print {
    html,
    body {
        height: initial !important;
        -webkit-print-color-adjust: exact;
    }
    #chart-grid {
        height: 85vh;
        display: flex !important;
    }
    #compliance-chart {
        background: white;
    }
    #button-download {
        display: none;
    }
}

@page {
    size: 100vh;
    margin: 0mm;
}
